export default `<header class="hero flex bg-cover" id="top">
<div class="wrapper cf">
  <hgroup class="hero__headings flex">
    <span class="hero__atp-logo bg-cover">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/atresplayer-logo.svg" />
    </span>
    <h1 class="title device">Suscripción atresplayer premium</h1>
    <strong class="claim">¿A qué estas esperando para ser <span>premium</span>?</strong>
    <div class="hero__planes">
      <p class="explanation">Elige el plan que mejor se adapte a ti</p>
      <div class="row">
        <!-- plan -->
        <div class="hero__plan">
          <div class="hero__plan-list">
            <h2 class="hero__plan-logo">PLAN <span>premium</span></h2>
            <ul class="hero__list">
              <li>Originales y exclusivos</li>
              <li>Preestrenos</li>
              <li>Todo el catálogo</li>
              <li>Últimos 7 días</li>
              <li>Calidad 1080p</h2></li>
            </ul>
          </div>
          <div class="hero__plan-precios">
            <span class="precio">
              <span>4,99€<span class="alt">/mes</span></span>
            </span>
            <span class="precio alt">
              <span>49,99€<span class="alt">/año</span></span>
              <span class="add">15% de descuento</span>
            </span>
          </div>
        </div>
        <!-- plan -->
        <div class="hero__plan familiar">
          <div class="hero__plan-list">
            <h2 class="hero__plan-logo">PLAN <span>premium familiar</span></h2>
            <ul class="hero__list">
              <li>Originales y exclusivos</h2></li>
              <li>Preestrenos</h2></li>
              <li>Todo el catálogo</h2></li>
              <li>Últimos 7 días</h2></li>
              <li><strong class="alt">Calidad 4K</strong></li>
              <li><strong class="alt">3 perfiles</strong></li>
              <li><strong class="alt">Sin publicidad</strong></li>
              <li><strong class="alt">Descarga offline</strong></li>
            </ul>
          </div>
          <div class="hero__plan-precios">
            <span class="precio">
              <span>7,99€<span class="alt">/mes</span></span>
            </span>
            <span class="precio alt">
              <span>79,99€<span class="alt">/año</span></span>
              <span class="add">15% de descuento</span>
            </span>
          </div>
        </div>
      </div>
      <a class="button" alt="Suscríbete ya a atresplayer premium" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">SUSCRÍBETE YA</a>
    </div>
    
  </hgroup>

<span class="hero__scroll bg-cover"></span>
</div>
</header>
<section class="content">
<div class="wrapper cf">
  <ul class="content__features">
    <li>
      <a class="scroll-to" data-scroll="#originales">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/originales.svg" alt="Originales y exclusivos"/>
        <p>Originales y exclusivos</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#preestrenos">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/preestrenos.svg" alt="Preestrenos"/>
        <p>Preestrenos</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#catalogo">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/catalogo.svg" alt="Todo el catálogo"/>
        <p>Todo el catálogo</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#u7d">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/u7d.svg" alt="Últimos 7 días"/>
        <p>Últimos 7 días</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#sinpubli">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/sinpublicidad.svg" alt="Sin publicidad"/>
        <p>Sin publicidad</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#3perfiles">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/3perfiles.svg" alt="3 perfiles"/>
        <p>3 perfiles</p>
      </a>
    </li>
    <li>
      <a class="scroll-to" data-scroll="#offline">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/descargaoffline.svg" alt="Descarga offline"/>
        <p>Descarga offline</p>
      </a>
    </li> 
    <li>
      <a class="scroll-to" data-scroll="#4k">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/calidad4k.svg" alt="Calidad 4K"/>
        <p>Calidad 4K</p>
      </a>
    </li>      
  </ul>
</div>  
<div class="wrapper cf">
<h2 class="claim_features">Ventajas de contratar atresplayer <span>premium</span></h2>
  <article id="originales" class="content__item flex">
    <figure class="content__item-image">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/originales.png" alt="Originales y exclusivos">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/originales.svg">
      </span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <h3 class="title primary-color">Contenido original y exclusivo</h3>
      <p class="text">Accede a los mejores contenidos originales y exclusivos solo disponibles en atresplayer premium.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">Hazte Premium</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-1.jpg" class="content-bg">
  </article>
  <article id="preestrenos" class="content__item flex">
    <figure class="content__item-image">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/preestrenos.png" alt="Preestrenos">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/preestrenos.svg">
      </span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <h3 class="title primary-color">Preestrenos</h3>
      <p class="text">Adelántate a la emisión en televisión de tus series, programas y telenovelas favoritos con los preestrenos exclusivos premium.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">Todos los preestrenos</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-2.jpg" class="content-bg">
  </article>
  <article id="catalogo" class="content__item flex">
    <figure class="content__item-image">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/catalogo.png" alt="Todo el catálogo">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/catalogo.svg">
      </span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <h3 class="title primary-color">Todo el catálogo</h3>
      <p class="text">Disfruta de los últimos contenidos publicados y las emisiones en directo de nuestros canales.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">HAZTE PREMIUM</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-3.jpg" class="content-bg">
  </article>
  <article id="u7d" class="content__item flex">
    <figure class="content__item-image light-gradient">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/u7d.png" alt="Últimos 7 días">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/u7d.svg">
      </span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <h3 class="title primary-color">Últimos 7 días</h3>
      <p class="text">Si te has perdido la emisión en directo de tu programa o serie de cualquiera de los canales de Atresmedia, tranquilidad, siendo premium podrás verlo hasta 7 días después de su emisión en TV en atresplayer premium.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">Disfruta hasta 7 días después</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-4.jpg" class="content-bg">
  </article>
  <article id="sinpubli" class="content__item familiar flex">
    <figure class="content__item-image light-gradient">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/sin_publicidad.png" alt="Sin publicidad">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/sinpubli.svg">
      </span>
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <span class="solo-familiar"></span>
      <h3 class="title primary-color">Sin publicidad</h3>
      <p class="text">Todo el contenido sin anuncios, solo tú decides cuándo pausar una serie o programa e ir a por palomitas.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">No más interrupciones</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-1.jpg" class="content-bg">
  </article>
<article id="3perfiles" class="content__item familiar flex"> 
    <figure class="content__item-image">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/3perfiles.png" alt="3 perfiles">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/3perfiles.svg">
      </span>
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <span class="solo-familiar"></span>
      <h3 class="title primary-color">3 perfiles</h3>
      <p class="text">Puedes tener hasta 3 perfiles individuales con recomendaciones basadas en las preferencias de cada usuario.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">Comparte tu atresplayer</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-2.jpg" class="content-bg">
  </article>
  <article id="offline" class="content__item familiar flex">
    <figure class="content__item-image no-gradient">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/offline.png" alt="Descarga offline">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/offline.svg">
      </span>
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <span class="solo-familiar"></span>
      <h3 class="title primary-color">Descarga offline</h3>
      <p class="text">Las series y programas de Atresmedia donde quieras. Allá donde vayas, no dejes de ver lo que te gusta sin necesidad de conexión.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">Donde y cuando quieras</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-3.jpg" class="content-bg">
  </article>
  <article id="4k" class="content__item familiar flex"> 
    <figure class="content__item-image">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/4k.png" alt="Calidad 4K">
      <span class="feature-arrow">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/4k.svg">
      </span>
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.03" data-paroller-type="foreground" data-paroller-direction="vertical">
      <span class="solo-familiar"></span>
      <h3 class="title primary-color">Calidad 4K</h3>
      <p class="text">Calidad HD-4K, el mejor sonido, VO y con subtítulos. Todo para que disfrutes como nunca de tu contenido favorito.</p>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">LA CALIDAD QUE TE MERECES</a>
    </hgroup>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-4.jpg" class="content-bg"> 
  </article>
</div>
</section>
<section class="choose_plan" id="tabla">
<h2 class="claim_package">¿Cuánto cuesta atresplayer <span>premium</span>?</h2>
<p class="choose_plan__text">Escoge tu plan</p>
<div class="choose_plan__table">
<table>
  <thead>
  <tr>
    <th></th>
    <th>PLAN <span>premium</span></th>
    <th>PLAN <span>premium familiar</span></th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="feature">Publicidad</td>
    <td class="type-adv">Carga mínima</td>
    <td class="type-adv">Sin publicidad</td>
  </tr>
  <tr>
    <td class="feature">Perfiles (sin restricciones)</td>
    <td>1</td>
    <td>3</td>
  </tr>
  <tr>
    <td class="feature">Originales y exclusivos</td>
    <td class="check"></td>
    <td class="check"></td>
  </tr>
  <tr>
    <td class="feature">Preestrenos</td>
    <td class="check"></td>
    <td class="check"></td>
  </tr>
  <tr>
    <td class="feature">Catálogo</td>
    <td class="check"></td>
    <td class="check"></td>
  </tr>
  <tr>
    <td class="feature">U7D</td>
    <td class="check"></td>
    <td class="check"></td>
  </tr>
  <tr>
    <td class="feature">Descargas</td>
    <td class="aspa"></td>
    <td class="check"></td>
  </tr>
  <tr>
    <td class="feature">Calidad de imagen</td>
    <td>1080</td>
    <td>4K</td>
  </tr>
  <tr class="choose_plan__table_price">
    <td></td>
    <td><span>4,99€/mes</span><br/>
    <span>49,99€/año</span></td>
    <td><span>7,99€/mes</span><br/>
    <span>79,99€/año</span></td>
  </tr>
  </tbody>
</table>
</div>
<!--<p class="bases-legales device">Consulta las <a  href="bases-legales/" target="_blank">bases legales</a></p>-->

<div class="choose_plan__carousel_container">
<div class="carousel-content">
<div class="hero__plan">
  <div class="hero__plan-list">
    <p class="hero__plan-logo">PLAN<span>premium</span></p>
    <ul class="hero__list">
      <li class="check"><p>Con publicidad</p></li>
      <li class="check"><p>Sólo 1 perfil</p></li>
      <li class="check"><p>Calidad 1080p</p></li>
      <li class="check"><p>Originales y exclusivos</p></li>
      <li class="check"><p>Preestrenos</p></li>
      <li class="check"><p>Todo el catálogo</p></li>
      <li class="check"><p>U7D</p></li>
      <li><p>Descarga offline</p></li>
    </ul>
  </div>
  <div class="hero__plan-precios">
    <span class="precio">
      <span>4,99€<span class="alt">/mes</span></span>
    </span>
    <span class="precio alt">
      <span>49,99€<span class="alt">/año</span></span>
      <span class="add">15% de descuento</span>
    </span>
  </div>
</div>
<div class="hero__plan">
  <div class="hero__plan-list">
    <p class="hero__plan-logo">PLAN<span>premium familiar</span></p>
    <ul class="hero__list">
      <li class="check"><p><strong>Sin publicidad</strong></p></li>
      <li class="check"><p><strong>Tres perfiles</strong></p></li>
      <li class="check"><p><strong>Calidad 4K</strong></p></li>
      <li class="check"><p>Originales y exclusivos</p></li>
      <li class="check"><p>Preestrenos</p></li>
      <li class="check"><p>Todo el catálogo</p></li>
      <li class="check"><p>U7D</p></li>
      <li class="check"><p><strong>Descarga offline</strong></p></li>
    </ul>
  </div>
  <div class="hero__plan-precios">
    <span class="precio">
      <span>4,99€<span class="alt">/mes</span></span>
    </span>
    <span class="precio alt">
      <span>49,99€<span class="alt">/año</span></span>
      <span class="add">15% de descuento</span>
    </span>
  </div>
</div>
</div>

<div class="bullets">
<span class="bullet"></span>
<span class="bullet"></span>
</div>
<!--<p class="bases-legales">Consulta las <a  href="bases-legales/" target="_blank">bases legales</a></p>-->
</div>

<span class="hero__scroll bg-cover"></span>
</section>

<section class="bottom__call">
<div class="wrapper">
  <article class="box flex">
    <span class="hero__atp-logo bg-cover">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/atresplayer-logo.svg" />
    </span>
    <!--<span class="hero__scroll bg-cover"></span>-->
    <h2>Disfruta de los mejores contenidos <span>originales</span> y <span>exclusivos</span> en atresplayer premium</h2>
    <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">HAZTE PREMIUM</a>
  </article>
</div>
</section>
<footer class="foo flex">
<div class="wrapper cf">
  <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
</div>
</footer>`;
